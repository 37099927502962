import React from "react"

import ToolLayout from "../../components/layout/tool"

export default () => {
  return (
    <ToolLayout title="Tools">
      <div className="headline">
        <h1 className="text-primary m-0">Tools</h1>
      </div>
      <p>Check the list from the left.</p>
    </ToolLayout>
  )
}
